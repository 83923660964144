import React from "react";
import { Link } from "react-router-dom";

const InvestC = () => {
  return (
    <section className="padding">
      <div className="h-full md:w-[60%] w-full">
        <div className={`xs:mt-[20px] mt-[6px]`}>
          <Link
            to={"/about"}
            className="flex  items-center xs:mt-[16px] mt-[8px] xs:mb-[20px] mb-[20px]"
          >
            <img
              src="/images/arrow-left.svg"
              alt="arrow_blue"
              className="w-[20px] h-[20px] mb-[-5px] "
            />
            <p className="mediumF text-lightBlue text-[16px] underline hover:underline-darkGray cursor-pointer">
              About us
            </p>
          </Link>
        </div>
      </div>

      {/* Management */}
      <h1 className="text-center blue_gradient_text mt-[41px] md:text-[32px] sm:text-[25px] text-[20px] boldF leading-[38px]">
        Meet our Investment Advisors
      </h1>

      <section className="flex items-center  xs:mb-[20px] mb-[10px]">
        <h2 className="sm:text-[20px] text-[18px] sm:w-[220px] w-full boldF text-darkGray leading-[24px]">
        Investment Advisors
        </h2>
        <div className="bg-[#DCDDDD] w-[80%] h-[1px] md:block hidden mt-[4px]"></div>
      </section>
    </section>
  );
};

export default InvestC;
